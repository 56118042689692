<template>
  <v-app>
    <page-header></page-header>
    <v-main>
      <DemoFlights/>
    </v-main>
    <page-footer></page-footer>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import DemoFlights from '@/components/DemoFlights';
import "@/style/global.scss";
import PageFooter from "@/components/common/PageFooter.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: 'App',

  components: {
    // HelloWorld,
    DemoFlights,
    PageHeader,
    PageFooter,
  },

  data: () => ({
    //
  }),
  created: function() {
    var currentUrl = window.location.pathname;
    console.log("currentURL:" + currentUrl);
  }
};
</script>
