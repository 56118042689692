<template>
  <v-container>
    <v-row class="text-center">
      <!--v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col-->

      <v-col class="mb-4">
        <h2 class="headline font-weight-bold mb-3">
          Demo for Flights Flow over the world
        </h2>
        <!-- <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Fangang.com
        </h1> -->
        <v-row justify="center">
          <div id="chart-flights" ref="chartflights" style="height:600px; width:100%;"></div>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          
        </h2>
      </v-col>

      <!--v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Ecosystem
        </h2>

        <v-row justify="center">
          <a
            v-for="(eco, i) in ecosystem"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a>
        </v-row>
      </v-col-->
    </v-row>
  </v-container>
</template>

<script>
// import * as echarts from 'echarts/core';
// import {
//     CanvasRenderer
// } from 'echarts/renderers';
// import { AxisPointerComponent, LegendComponent, TitleComponent, ToolboxComponent, 
//     DataZoomComponent, VisualMapComponent, TimelineComponent, CalendarComponent
//  } from 'echarts/components';
// import mapChinaJson from '@/map/json/china.json' // 这个是json引用
// import {
//     Lines3DChart
// } from 'echarts-gl/charts';
// import {
//     Geo3DComponent
// } from 'echarts-gl/components';
// echarts.use(
//     [Geo3DComponent, Lines3DChart, CanvasRenderer, AxisPointerComponent, 
//     LegendComponent, TitleComponent, ToolboxComponent, DataZoomComponent,
//     VisualMapComponent, TimelineComponent, CalendarComponent]
// );

import * as echarts from 'echarts';
import 'echarts-gl';
import axios from '@/utils/axios-ext';
import mapWorldJson from '@/map/json/world.json' // 这个是json引用

var ROOT_PATH = 'https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples';

export default {
  name: 'DemoFlights',

  data() {
    return {
      uploadedDataURL: ROOT_PATH + "/data-gl/asset/data/flights.json",
    }
  },
  methods: {
    initCharts () {
      // var chartDom = document.getElementById('main');
      var chartDom = this.$refs.chartflights
      var myChart = echarts.init(chartDom, 'dark');
      echarts.registerMap('world', mapWorldJson);
      var option;
      
      // var ROOT_PATH = 'https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples';
      // var uploadedDataURL = ROOT_PATH + "/data-gl/asset/data/flights.json";
      var uploadedDataURL = location.origin + "/data/flights.json";

      myChart.showLoading();
      
      console.log("ready to fetch JSON data from remote host");
      axios.get(uploadedDataURL)
        .catch( err=>{
                  console.log(err)
              }
        ).then((res) => {
          var data = res.data;
          myChart.hideLoading();
          function getAirportCoord(idx) {
            return [data.airports[idx][3], data.airports[idx][4]];
          }
      
          var routes = data.routes.map(function (airline) {
              return [
                  getAirportCoord(airline[1]),
                  getAirportCoord(airline[2])
              ];
          });

          myChart.setOption({
              geo3D: {
                  map: 'world',
                  shading: 'realistic',
                  silent: true,
                  environment: '#00ccdd',
                  realisticMaterial: {
                      roughness: 0.8,
                      metalness: 0
                  },
                  postEffect: {
                      enable: true
                  },
                  groundPlane: {
                      show: false
                  },
                  light: {
                      main: {
                          intensity: 1,
                          alpha: 30
                      },
                      ambient: {
                          intensity: 0
                      }
                  },
                  viewControl: {
                      distance: 70,
                      alpha: 89,

                      panMouseButton: 'left',
                      rotateMouseButton: 'right'
                  },

                  itemStyle: {
                      color: '#000'
                  },

                  regionHeight: 0.5
              },
              series: [{
                  type: 'lines3D',

                  coordinateSystem: 'geo3D',

                  effect: {
                      show: true,
                      trailWidth: 1,
                      trailOpacity: 0.5,
                      trailLength: 0.2,
                      constantSpeed: 5
                  },

                  blendMode: 'lighter',
      
                  lineStyle: {
                      width: 0.2,
                      opacity: 0.05
                  },
      
                  data: routes
              }]
          });

          window.addEventListener('keydown', function () {
              myChart.dispatchAction({
                  type: 'lines3DToggleEffect',
                  seriesIndex: 0
              });
          });
      });

      option && myChart.setOption(option);

    },
  },
  mounted () {
    console.log("ready to call initCharts() ");
    this.initCharts();
  },
}
</script>
<style lang="scss" scoped>
  .container {
    // max-width:100%;
  }
</style>